import React from 'react'

const IndexPage = () => (
  <div className='content'>
    <br />
    <h1>Page not found</h1>
  </div>
)

export default IndexPage
